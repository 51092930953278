.popup {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 5em;

  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}
.popupContent {
  font-size: 0.9rem;
  padding: 1em 2em 0.5em 2em;
  color: white;
  background-color: #302f4e;
  margin: auto;
  border-radius: 10px;
  max-width: 550px;
  height: auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.buttonGroup {
  align-items: flex-end;
  display: flex;

  div {
    margin-left: 10px;
  }
}

.spanChanges {
  margin: 0em 1em 0em 1em;
  font-size: 0.8rem;
}
