


@keyframes ldio_w5fi8i3miu8 {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio_w5fi8i3miu8 div > div {
  position: absolute;
  width: 14.16px;
  height: 14.16px;
  border-radius: 50%;
  background: #252444;
  animation: ldio_w5fi8i3miu8 1s linear infinite;
}.ldio_w5fi8i3miu8 div:nth-child(1) > div {
  left: 87px;
  top: 52px;
  animation-delay: -0.875s;
}
.ldio_w5fi8i3miu8 > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 94.08px 59.08px;
}.ldio_w5fi8i3miu8 div:nth-child(2) > div {
  left: 77px;
  top: 77px;
  animation-delay: -0.75s;
}
.ldio_w5fi8i3miu8 > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 84.08px 84.08px;
}.ldio_w5fi8i3miu8 div:nth-child(3) > div {
  left: 52px;
  top: 87px;
  animation-delay: -0.625s;
}
.ldio_w5fi8i3miu8 > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 59.08px 94.08px;
}.ldio_w5fi8i3miu8 div:nth-child(4) > div {
  left: 27px;
  top: 77px;
  animation-delay: -0.5s;
}
.ldio_w5fi8i3miu8 > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 34.08px 84.08px;
}.ldio_w5fi8i3miu8 div:nth-child(5) > div {
  left: 17px;
  top: 52px;
  animation-delay: -0.375s;
}
.ldio_w5fi8i3miu8 > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 24.08px 59.08px;
}.ldio_w5fi8i3miu8 div:nth-child(6) > div {
  left: 27px;
  top: 27px;
  animation-delay: -0.25s;
}
.ldio_w5fi8i3miu8 > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 34.08px 34.08px;
}.ldio_w5fi8i3miu8 div:nth-child(7) > div {
  left: 52px;
  top: 17px;
  animation-delay: -0.125s;
}
.ldio_w5fi8i3miu8 > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 59.08px 24.08px;
}.ldio_w5fi8i3miu8 div:nth-child(8) > div {
  left: 77px;
  top: 27px;
  animation-delay: 0s;
}
.ldio_w5fi8i3miu8 > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 84.08px 34.08px;
}
.loadingio_spinner_spin_nx4ifpok5e {
  width: 118px;
  height: 118px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio_w5fi8i3miu8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio_w5fi8i3miu8 div { box-sizing: content-box; }

.serverWrapper {
  padding: 2.5em 0 2.5em 0;
}
.serversContainer {
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.serverItem {
  padding: 1em;
  color: #b7b7b7;
  background-color: #3f4461;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.createServerButton {
  width:7em;
    font-size: 15px;
    background-color: #4caf50;
    color: white;
    &:hover {
      background-color: #189816;
    }
  
}
.titleContainer {
  span {
    font-size: 2.5rem;
  }
  margin-top:2em;
  display: flex;
  justify-content: space-between;
}
.monitoringGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: repeat(2, 10em);
  grid-gap: 20px;
  padding-bottom: 2em;
}
.activeWebsite {
  background-color: #302f4e !important;
}
.externalWebsite {
  user-select: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #3f4461;
}
.createServerContainer {
  margin-top: 1em;
  color: silver;
  padding: 0.5em;
  font-size: 1.2rem;
  background-color: #484c71;

  border-radius: 5px;
}
.center {
  text-align: center;
}
.addServers {
  font-size: 1.2rem;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputField {
  margin-right: 1em;
  display: flex;
  align-items: center;

  input,
  select {
    height: 20px;
    margin-left: 0.5em;
    color: white;
    text-indent: 0.5em;
    &:focus {
      outline: 2px solid #302f4e;
    }
    background-color: #484c71;
    border: none;
    border-radius: 5px;
  }
  select {
    width: 5.5 em;
  }
}
.accountContainer {
  padding: 1px;
}


.accountsWrapper {
  
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.container {
  button {
    margin-bottom: 0.5em;
  }
  margin-top: 1em;
  display: flex;
  justify-content: space-around;
}
.actionContainer {
  width: 150px;
  display: flex;
  flex-direction: column;
}
.emptyAccounts {
  text-align: center;
}
.lastManagedServer {
  background-color:blue !important;
}
.serverTitle {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 1.5rem;
  }
}

.green {
  color: white;
  background-color: #347474;
  &:hover {
    background-color: #2a5b5b;
  }
}
.editServer {
  display: flex;
  span {
    margin-right: 0.25em;
    font-size: 1rem;
  }
  align-items: center;
}
.activeAccs {
  margin-right: 0.5em;
}
.popup {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 1em;

  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}
.popupContent {
  padding: 1em 2em 0.01em 2em;
  color: white;
  background-color: #302f4e;
  margin: auto;
  border-radius: 10px;
  max-width: 1200px;
  height: auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.buttonGroup {
  align-items: flex-end;
  display: flex;

  div {
    margin-left: 10px;
  }
}
.red {
  background-color: red;
  &:hover {
    background-color: rgb(168, 37, 37);
  }
}

.reloadButton {
  margin-left: 1em;
  cursor: pointer;
  background-color: #3f4461;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.5em 0.5em 0.5em;

  border-radius: 6px;

  span {
    padding: 0px 5px 0px 5px;
  }
}
.scroll {
  overflow-y: auto;
  // height: 40px;
  // max-width: 480px;
  // overflow-x: auto;
  max-height: 300px;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.screenshotsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding-bottom: 2em;
}
.screenshotsGrid2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1px;
  width:min-content;
}
@media all and (min-width: 2400px) and (max-width: 2800px) { 
  .screenshotsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .screenshotsGrid2 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media all and (min-width: 1920px) and (max-width: 2400px) { 
  .screenshotsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .screenshotsGrid2 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media all and (min-width: 1600px) and (max-width: 1920px) { 
  .screenshotsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .screenshotsGrid2 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media all and (min-width: 1280px) and (max-width: 1600px) { 
  .screenshotsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  }
  .screenshotsGrid2 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  

}
@media all and (min-width: 1024px) and (max-width: 1280px) { 

  .screenshotsGrid {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  }
  .screenshotsGrid2 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .screenshotsGrid {
    grid-template-columns: 1fr 1fr 1fr ;
  
    }
    .screenshotsGrid2 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
 }

@media all and (min-width: 480px) and (max-width: 768px) { 
  .screenshotsGrid {
    grid-template-columns: 1fr 1fr  ;
  
    }
    .screenshotsGrid2 {
      grid-template-columns: 1fr 1fr 1fr ;
    }
  
}

@media all and (min-width: 0px) and (max-width: 480px) { 

  .screenshotsGrid {
    grid-template-columns: 1fr 1fr  ;
  
    }
    .screenshotsGrid2 {
      grid-template-columns: 1fr 1fr;
    }
}