.optionsPanel {
  color: #b7b7b7;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4em;
}

.optionsButton {
  cursor: pointer;
  background-color: #3f4461;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.5em 0.5em 0.5em;

  border-radius: 6px;

  span {
    padding: 0px 5px 0px 5px;
  }
}
.buttonGroupActions {
  user-select: none;

  font-size: 0.7rem;
  div {
    margin-left: 0.5em;
  }
  display: flex;
  flex-flow: row;
}
.img {
  width: 30px;
  height: 30px;
}
.selectedAccounts {
  display: flex;
  align-items: center;
  // margin-right: 0.5em;
}
.filterRegions {
  margin-bottom: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filterRegionsButtons {
  display: flex;
  flex-direction: row;
}
.activeRegions {
  span {
    font-size: 0.9rem;
    margin-right: 0.5em;
  }
  font-size: 0.7rem;
  user-select: none;
  cursor: pointer;
  margin-right: 0.5em;
  padding: 10px;
  // width: min-content;
  border-radius: 5px;
  background-color: #302f4e;
}
.nonActiveRegions {
  span {
    font-size: 0.9rem;
    margin-right: 0.5em;
  }
  // width: min-content;

  font-size: 0.7rem;
  user-select: none;
  cursor: pointer;
  margin-right: 0.5em;
  padding: 10px;
  border-radius: 5px;
  background-color: #3f4461;
}
.buttonGroup {
  user-select: none;
  font-size: 0.8rem;

  display: flex;
  flex-flow: row;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  span {
    font-size: 0.7rem;
  }
  visibility: hidden;
  width: 120px;
  background-color: #26283a;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: -180%;
  left: 0%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
}

.tooltip:hover .tooltiptext {
  width: fit-content;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
}
