.tasksTitle {
  font-size:2em;
  margin: 5px 0px 5px 0px;
}
.taskName {
  font-size:2em;
}
.removeTaskButton {
  width:7em;
    font-size: 15px;
    background-color: #e33434;
    color: white;
    &:hover {
      background-color: #981616;
    }
  
}

.editTaskButton {
  width:7em;
    font-size: 15px;
    background-color: #302f4e;
    color: white;
    &:hover {
      background-color: #25243d;
    }
  
}
.taskItem {
  margin: 2em 0em 2em 0em;
  padding: 1em;
    color: #b7b7b7;
    background-color: #3f4461;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.taskFilters {
  display:flex;
  flex-direction: row;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom:1em;
 
}
.taskFilterItem {
  margin:0em 1em 0em 0em;
  background-color: #3b405e;
  padding:1em;
    border: 1px solid #302f4e;
    border-radius: 5px;
  max-width:23em;
  
  gap:1em;
  display: flex;
  flex-direction: column;
}

.popup {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 5em;

  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}
.popupContent {
  font-size: 0.9rem;
  padding: 1em 2em 0.01em 2em;
  color: white;
  background-color: #302f4e;
  margin: auto;
  border-radius: 10px;
  max-width: 1300px;
  height: auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.buttonGroup {
  align-items: flex-end;
  display: flex;

  div {
    margin-left: 10px;
  }
}

.spanChanges {
  margin: 0em 1em 0em 1em;
  font-size: 0.8rem;
}
