.passwordSection {
	display: flex;
	font-size: 1.1rem;
	align-items: center;
}
.settingsSection {
	display: flex;
	flex-direction: column;
	font-size: 1.1rem;
}
.passwordField {
	// label {
	//   margin-right: 0.5em;
	//   margin-left: 0.5em;
	// }

	input {
		margin-left: 0.5em;
	}
	// justify-content: space-between;
	margin-top: 0.25em;
	display: flex;
	align-items: center;
}

.settingsContainer {
	// margin-top: 4em;
	input {
		color: white;
		text-indent: 0.5em;
		height: 20px;

		background-color: #484c71;
		border: none;
		border-radius: 5px;
		border: none;
	}
}

.overview {
	font-size: 0.8rem;
	margin-top: 5em;
}
.overviewContainer {
	color: #b7b7b7;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: repeat(1, 10em);
	grid-gap: 20px;
}
.overviewItem {
	background-color: #3f4461;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 5px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

	i {
		color: #252444;
		font-size: 3.5rem;
	}
	h2 {
		margin-bottom: 1em;
	}
	span {
		font-size: 1.5em;
	}
}
.overviewItemDesc {
	margin-bottom: 3em;
}

.activeWebsite {
	background-color: #302f4e !important;
}
.externalWebsite {
	user-select: none;
	cursor: pointer;
	margin-left: 0.5em;
	padding: 10px;
	border-radius: 5px;
	background-color: #3f4461;
}

.error {
	margin-top: 0.5em;
	color: red;
	font-size: 1.1rem;
}
.success {
	margin-top: 0.5em;
	color: green;
	font-size: 1.1rem;
}

.input {
	color: white;
	height: 20px;
	background-color: #484c71;
	border: none;
	border-radius: 5px;
	width: 75px;
}

.exportItem {
	display:flex;
	align-items: center;
	user-select: none;
	font-size: 0.78em;
	color: white;
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	padding: 5px;
	border-radius: 5px;
	background-color: #3f4461;
	i {
		cursor: pointer;
		font-size: 0.7rem;
		color: #b7b7b7;
	}
}

.field {
	margin-left: 1em;
	display: flex;
	align-items: center;
	input {
		margin-right: 0.5em;
	}
}
.licensesGrid {
	padding-top: 1em;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	grid-gap: 20px;
}
.licenseItem {
	p {
		span {
			color: #b7b7b7;
		}
	}
	span {
		span {
			color: #b7b7b7;
		}
	}
	font-size: 0.85rem;
	padding: 1em;
	background-color: #3f4461;
	border-radius: 5px;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
