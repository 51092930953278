#root {
	height: inherit;
}
body {
	margin: 0;
	font-family: 'Lato', sans-serif;
	height: inherit;
	background-color: #3b3e59;
	color: white;
}

html {
	height: 100%;
}

button,
input[type='submit'],
input[type='reset'] {
	background: none;
	color: inherit;
	border: none;

	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.container {
	height: inherit;
}
.main {
	width: 1300px;
	margin: auto;
	margin-bottom: 5em;
	// height: 100%;
}
.credentials-field {
	display: flex;
	flex-direction: column;
	input {
		width: 9em;
	}
}
/* UTILS */
.tooltip {
	cursor: default;
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}
.options-button {
	cursor: pointer;
	background-color: #3f4461;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	font-size: 15px;
	border-radius: 6px;
	height: 30px;
	width: 250px;

	span {
		padding: 0px 5px 0px 5px;
	}
}
.tooltip .tooltiptext {
	border: 1px solid #1c1e36;
	visibility: hidden;
	width: 120px;
	background-color: #3b3e59;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
	content: '';
	position: absolute;
}

.tooltip:hover .tooltiptext {
	width: fit-content;
	white-space: nowrap;
	visibility: visible;
	opacity: 1;
}
.button {
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	font-size: 15px;
	border-radius: 6px;
	height: 30px;
	width: 60px;
	&:hover {
		cursor: pointer;
	}
	span {
		padding: 0px 5px 0px 5px;
	}
	-webkit-transition: 0.5s;

	transition: all 0.3s linear;
}
.anim {
	-webkit-transition: 0.5s;

	transition: all 0.3s linear;
}
.red_ {
	background-color: #c02323;
	color: white;
	&:hover {
		background-color: #a90606;
	}
}

.aqua-green {
	background-color: #4caf50;
	color: white;
	&:hover {
		background-color: #419544;
	}
}
.center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.disabled {
	color: #0000002e !important;
	cursor: default !important;
	pointer-events: none;
}
/* NAVBAR */

.navbar {
	a {
		margin-right: 1em;
	}
	background-color: #302f4e;
	height: 80px;
	display: flex;
	border-top: 10px solid #222831;

	&__items {
		display: flex;
	}
	&__item {
		color: #4caf50;
		padding: 5px;
		font-size: 2rem;
	}
	&__logo {
		a {
			text-decoration: none;
			color: inherit;
		}
		font-size: 2rem;
		color: white;
	}
	&__container {
		width: 1200px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__button-group {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}
	&__login-info {
		margin-right: 10px;
		color: white;
	}
}
/* ACCOUNTS */
.accounts {
	padding-bottom: 2.5em;
	margin: 5em 0em 5em 0em;
	&__status {
		min-width: 110px;
	}

	&__buttons {
		display: flex;
		justify-content: space-evenly;
		i {
			color: #1c4141;
			padding: 0px 2px 0px 2px;
			// opacity: 20%;
		}
	}
	&__button {
		cursor: pointer;
	}
}
table {
	width: 100%;
	border-radius: 6px;
	// border: solid 2px black;

	font-size: 15px;

	border-collapse: collapse;
	color: #1f1f1f;
	// width: 100%;
	th {
		user-select: none;
		font-weight: 100;
		border-bottom: 1px solid black;
	}
}

table td,
table th {
	// border: 1px solid #ddd;

	padding: 5px;
	text-align: center;
	justify-content: center;
	a {
		text-decoration: none;
		color: inherit;
	}
}

table tr:nth-child(even) {
	background-color: #a8a8a8;
}

table tr:nth-child(odd) {
	background-color: #7f9597;
}
table tr:hover {
	// background-color: #ddd;
}

table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #347474;
	color: white;
}

/* LOGIN */
.login {
	padding: 150px 0px 0px 0px;
	display: flex;
	justify-content: center;
	&__title {
		text-align: center;
		padding: 0px 0px 20px 0px;
		font-size: 2rem;
	}
	&__container {
		background-color: #434361;
		padding: 50px;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	}
	&__form {
		&__fields {
			display: flex;
			flex-direction: column;
			input {
				color: white;
				width: 400px;
				height: 50px;
				background-color: #302f4e;
				border: none;
				text-indent: 20px;
			}
		}
		&__field {
			display: flex;
			flex-direction: column;
			padding: 15px 0px 15px 0px;
		}
	}
}

.textarea-summoner-names {
	resize: none;
	color: silver;
	padding: 0.5em;
	font-size: 1.2rem;
	background-color: #484c71;
	min-height: 25.6em;
	border: none;
	border-radius: 5px;
	width: 100%;
}
.overview {
	font-size: 0.68rem;
	margin-top: 5em;
	&__container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: repeat(2, 10em);
		grid-gap: 20px;
	}
	&__item {
		color: #b7b7b7;
		background-color: #3f4461;
		.fas {
			color: #252444;
		}
		i {
			font-size: 3rem;
		}
		h2 {
			margin-bottom: 1em;
		}
		span {
			font-size: 2em;
		}
		&__percentage {
			color: #a6a6a6;
			font-size: 0.9rem !important;
			margin-left: 0.5em;
		}
		text-align: center;

		display: flex;
		justify-content: space-around;
		align-items: center;
		border-radius: 5px;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
		&__desc {
			margin-bottom: 3em;
		}
	}
}

.spinner {
	display: flex;
	justify-content: center;
}
.credentials {
	padding: 5em 0em 2.5em 0em;

	&__container {
		margin-top: 2.5em;
		// height: 700px;
		// border: 1px solid #434361;
		// background-color: #4c4f77;
		// border-radius: 5px;
	}
	&__title-container {
		display: flex;
		justify-content: space-between;
	}
	&__title {
		font-size: 2.5rem;
	}
	&__button {
		padding: 2em;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		font-size: 15px;
		border-radius: 6px;
		height: 40px;

		span {
			padding: 0px 5px 0px 5px;
		}
	}
	&__confirmation {
		margin-top: 5em;
		display: flex;
		justify-content: center;
		input {
			color: white;
			text-indent: 0.5em;
			&:focus {
				outline: 2px solid #302f4e;
			}
			background-color: #484c71;
			border: none;
			border-radius: 5px;
		}
	}
	&__delete-button {
		cursor: pointer;
	}
	&__addaccounts {
		display: flex;
		justify-content: space-between;
		textarea {
			&:focus {
				outline: 2px solid #302f4e;
			}
			resize: none;

			color: rgb(192, 192, 192);
			padding: 0.5em;
			font-size: 1.2rem;

			background-color: #484c71;
			min-height: 25.6em;
			border: none;
			border-radius: 5px;
			width: 500px;
		}
	}
	&__info__container {
		font-size: 0.9rem;
		width: 550px;
	}
	&__info-row {
		height: 40px;
		font-size: 1.2em;
		margin-bottom: 0.5em;
		display: flex;
		justify-content: space-between;
	}
	&__info-button {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
	&__input {
		color: white;
		margin-top: 0.4em;
		text-indent: 0.5em;
		height: 20px;
		&:focus {
			outline: 2px solid #302f4e;
		}
		background-color: #484c71;
		border: none;
		border-radius: 5px;
	}
	&__info-field {
		color: rgb(192, 192, 192);
		// padding: 0em 2em 0em 2em;
		display: flex;
		flex-direction: column;
	}
	&__password {
		span {
			cursor: pointer;
		}
	}
	&__showcredentials {
		// input {
		//   text-indent: 10px;
		//   color: #cfcfcf;
		//   outline: none;
		//   height: auto;
		//   background-color: #3a3b69;
		//   border: 1px solid #222831;
		// }
		button {
			margin-left: 1em;
		}
		margin-top: 2.5em;
	}
}

.buttonGroup {
	align-items: flex-end;
	display: flex;

	div {
		margin-left: 10px;
	}
}

.checkbox {
	border: none;
	&:focus {
		outline: none;
	}
}
@keyframes ldio-zun9bot0rhh {
	0% {
		opacity: 1;
		backface-visibility: hidden;
		transform: translateZ(0) scale(1.5, 1.5);
	}
	100% {
		opacity: 0;
		backface-visibility: hidden;
		transform: translateZ(0) scale(1, 1);
	}
}
.ldio-zun9bot0rhh div > div {
	position: absolute;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #252444;
	animation: ldio-zun9bot0rhh 1s linear infinite;
}
.ldio-zun9bot0rhh div:nth-child(1) > div {
	left: 148px;
	top: 88px;
	animation-delay: -0.875s;
}
.ldio-zun9bot0rhh > div:nth-child(1) {
	transform: rotate(0deg);
	transform-origin: 160px 100px;
}
.ldio-zun9bot0rhh div:nth-child(2) > div {
	left: 130px;
	top: 130px;
	animation-delay: -0.75s;
}
.ldio-zun9bot0rhh > div:nth-child(2) {
	transform: rotate(45deg);
	transform-origin: 142px 142px;
}
.ldio-zun9bot0rhh div:nth-child(3) > div {
	left: 88px;
	top: 148px;
	animation-delay: -0.625s;
}
.ldio-zun9bot0rhh > div:nth-child(3) {
	transform: rotate(90deg);
	transform-origin: 100px 160px;
}
.ldio-zun9bot0rhh div:nth-child(4) > div {
	left: 46px;
	top: 130px;
	animation-delay: -0.5s;
}
.ldio-zun9bot0rhh > div:nth-child(4) {
	transform: rotate(135deg);
	transform-origin: 58px 142px;
}
.ldio-zun9bot0rhh div:nth-child(5) > div {
	left: 28px;
	top: 88px;
	animation-delay: -0.375s;
}
.ldio-zun9bot0rhh > div:nth-child(5) {
	transform: rotate(180deg);
	transform-origin: 40px 100px;
}
.ldio-zun9bot0rhh div:nth-child(6) > div {
	left: 46px;
	top: 46px;
	animation-delay: -0.25s;
}
.ldio-zun9bot0rhh > div:nth-child(6) {
	transform: rotate(225deg);
	transform-origin: 58px 58px;
}
.ldio-zun9bot0rhh div:nth-child(7) > div {
	left: 88px;
	top: 28px;
	animation-delay: -0.125s;
}
.ldio-zun9bot0rhh > div:nth-child(7) {
	transform: rotate(270deg);
	transform-origin: 100px 40px;
}
.ldio-zun9bot0rhh div:nth-child(8) > div {
	left: 130px;
	top: 46px;
	animation-delay: 0s;
}
.ldio-zun9bot0rhh > div:nth-child(8) {
	transform: rotate(315deg);
	transform-origin: 142px 58px;
}
.loadingio-spinner-spin-su4iz61v888 {
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
	background: rgba(255, 255, 255, 0);
}
.ldio-zun9bot0rhh {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-zun9bot0rhh div {
	box-sizing: content-box;
}

.selected {
	background-color: #4caf50 !important;
}

@keyframes ldio-7vi82ejf5rx {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.ldio-7vi82ejf5rx div {
	left: 47px;
	top: 24px;
	position: absolute;
	animation: ldio-7vi82ejf5rx linear 1s infinite;
	background: #1c4141;
	width: 6px;
	height: 12px;
	border-radius: 3px / 6px;
	transform-origin: 3px 26px;
}
.ldio-7vi82ejf5rx div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -0.9166666666666666s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -0.8333333333333334s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.75s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.6666666666666666s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.5833333333333334s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.5s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.4166666666666667s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.3333333333333333s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.25s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.16666666666666666s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.08333333333333333s;
	background: #1c4141;
}
.ldio-7vi82ejf5rx div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
	background: #1c4141;
}
.loadingio-spinner-spinner-uh4mw6ckb98 {
	width: 18px;
	height: 18px;
	display: inline-block;
	overflow: hidden;
	background: rgba(255, 255, 255, 0);
}
.ldio-7vi82ejf5rx {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.18);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-7vi82ejf5rx div {
	box-sizing: content-box;
}

@keyframes ldio-qp87mbtkuu {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
// ----

@keyframes ldio-vbb5fe2lwi {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.ldio-vbb5fe2lwi div {
	position: absolute;
	animation: ldio-vbb5fe2lwi 1.35s linear infinite;
	width: 80px;
	height: 80px;
	top: 10px;
	left: 10px;
	border-radius: 50%;
	box-shadow: 0 8.2px 0 0 #ffffff;
	transform-origin: 40px 44.1px;
}
.loadingio-spinner-eclipse-gr3e527dds {
	width: 30px;
	height: 30px;
	display: inline-block;
	overflow: hidden;
	background: none;
}
.ldio-vbb5fe2lwi {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.3);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-vbb5fe2lwi div {
	box-sizing: content-box;
}
.stripe {
	width: -webkit-fill-available;
	// background-color: #1b1b1b;
	background-color: white;
	padding: 12px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
  
	&__form {
	  display: flex;
	  flex-direction: column;
	}
  }
  // Stripe buttons
  .btn {
	padding: 15px 24px;
	font-size: toRem(16);
	font-weight: 700;
	background: white;
	border-radius: 40px;
	border-color: transparent;
	cursor: pointer;
	box-shadow: 0 0 0 0 transparent; // Diego
	transition: box-shadow 0.2s ease-in-out; // Diego
	letter-spacing: 1px; // Diego
  
	&--orange {
	  background:linear-gradient(92.48deg, #fa8305 2.08%, #fb9e3c 117.25%);
	  color: #ffffff;
  
	  &:hover {
		-webkit-box-shadow: 0px 0px 15px 4px rgba(251, 159, 60, 0.35);
		-moz-box-shadow: 0px 0px 15px 4px rgba(251, 159, 60, 0.35);
		box-shadow: 0px 0px 15px 4px rgba(251, 159, 60, 0.35);
	  }
	}
  
	&--small {
	  font-size: toRem(10);
	  font-weight: 400;
	  padding: 8px 12px;
	}
  
	&--light {
	  background: #ffffff;
	  color: #fa8305;
  
	  &:hover {
		-webkit-box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
		-moz-box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
		box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
	  }
	}
  
	&--disabled {
	  background-color: #bfbfbf;
	  cursor: auto;
	  color: #ffffff;
	}
  
	&--cart {
	  background-color: transparent;
	  justify-self: center;
	  align-self: center;
	  cursor: pointer;
	  color: #c16400;
	  font-size: toRem(42);
	  transition: color 0.2s ease;
	  position: relative;
	  display: inline;
  
	  &::after {
		content: "CART";
		color: #c16400;
		display: block;
		position: absolute;
		font-size: toRem(12);
		width: 100%;
		text-align: center;
		left: 5%;
	  }
  
	  @media (min-width: 900px) {
		display: none;
	  }
  
	  &:hover {
		color: #fa8305;
	  }
  
	  &-counter {
		color: #ffffff;
		font-size: toRem(12);
		position: absolute;
		top: 0;
		left: 0;
		background-color: #f53838;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
	  }
	}
  
	&--buy-close {
	  background-color: #232323;
	  justify-self: center;
	  align-self: center;
	  cursor: pointer;
	  color: #f53838;
	  font-size: toRem(28);
	  // position: absolute;
	  top: 15px;
	  right: 15px;
	  border-radius: 100%;
	  height: 42px;
	  width: 42px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  transition: background-color, color 0.2s ease;
	  border: none;
  
	  @media (min-width: 420px) {
		position: absolute;
	  }
  
	  &:hover {
		background-color: #f53838;
		color: #ffffff;
	  }
	}
  
	&--back {
	  font-size: toRem(12);
	  padding: 8px 16px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  gap: 8px;
	}
  
	&--pay {
	  margin-top: 15px;
	}
  
	&--cart-close {
	  background-color: #232323;
	  justify-self: center;
	  align-self: center;
	  cursor: pointer;
	  color: #f53838;
	  font-size: toRem(28);
	  position: absolute;
	  top: -25px;
	  border-radius: 100%;
	  height: 42px;
	  width: 42px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  transition: background-color, color 0.2s ease;
	  border: none;
  
	  @media (max-height: 800px) {
		position: static;
	  }
  
	  &:hover {
		background-color: #f53838;
		color: #ffffff;
	  }
  
	  @media (min-width: 900px) {
		display: none;
	  }
	}
  }
  
  .coinbase-commerce-iframe-container {
    background-color: rgba(0,0,0,.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99998;
  }
  .coinbase-commerce-iframe {
    border: none;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
  }