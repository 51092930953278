.popup {
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	width: 100%;
	height: 100%;
	padding-top: 3em;

	background-color: black;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-transition: 0.5s;

	transition: all 0.3s linear;
}
.popupContent {
	padding: 1em 2em 1em 2em;
	color: white;
	background-color: #302f4e;
	margin: auto;
	border-radius: 10px;

	max-width: 1200px;

	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.buttonGroup {
	align-items: flex-end;
	display: flex;

	div {
		margin-left: 10px;
	}
}
.textarea {
	&:nth-child(even) {
		background-color: rgb(182, 176, 164);
	}
	span {
		margin-left: 0.2em;
	}
	img {
		margin-left: 0.2em;
	}
	overflow: auto;
	font-family: 'Lato';
	resize: none;
	color: silver;
	padding: 0.5em;
	font-size: 1.2rem;
	background-color: #484c71;
	border: none;
	border-radius: 5px;
	width: 50%;
	max-height: 36.8em;
}

.select {
	color: white;
	height: 20px;
	background-color: #484c71;
	border: none;
	border-radius: 5px;
}
.filterRegions {
	margin-bottom: 0.4em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.filterRegionsButtons {
	display: flex;
	flex-direction: row;
}
.activeRegions {
	font-size: 0.7rem;
	user-select: none;
	cursor: pointer;
	margin-right: 0.5em;
	padding: 10px;
	border-radius: 5px;
	background-color: #302f4e;
}
.nonActiveRegions {
	span {
		font-size: 0.9rem;
		margin-right: 0.5em;
	}
	font-size: 0.7rem;
	user-select: none;
	cursor: pointer;
	margin-right: 0.5em;
	padding: 10px;
	border-radius: 5px;
	background-color: #3f4461;
}
.buttonGroup {
	user-select: none;
	font-size: 0.8rem;

	display: flex;
	flex-flow: row;
}
.img {
	width: 30px;
	height: 30px;
}
