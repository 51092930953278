.historyContainer {
  padding-bottom: 2.5em;
  h1 {
    margin: 1em;
  }
}

.historyItemWrapper {
  margin: 1em;
}
.historyItem {
  // user-select: none;
  // cursor: pointer;
  padding: .7em;
  color: white;
  background-color: #302f4e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
}
.historyItemBody {
  padding: 1em;
  color: white;
  background-color: #494d69;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.grids {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.activeWebsite {
  background-color: #302f4e !important;
}
.externalWebsite {
  user-select: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #3f4461;
}
.optionsButton {
  user-select: none;
  cursor: pointer;
  background-color: #3f4461;
  height: 40px;
  margin-top: 1.5em;
  margin-right: 1.5em;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.5em 0.5em 0.5em;

  border-radius: 6px;

  span {
    padding: 0px 5px 0px 5px;
  }
}

.enabledPageChange {
  user-select: none;
  cursor: pointer;
}

.disabledPageChange {
  user-select: none;
  opacity: 30%;
  pointer-events: none;
}
