.paymentMethodsContainer {
    align-content: flex-start;
     align-items: center;
      flex-wrap: wrap;
       display: flex;
        flex-direction: column;
         margin: 20px 
}

.paymentMethod {
    width: -webkit-fill-available; 
    grid-column-gap: 20px; 
    align-items: center; 
    border-radius: 25px; 
    column-gap: 20px; 
    cursor: pointer; 
    display: grid; 
    grid-template-columns: auto 1fr; 
    justify-items: start; 
    padding: 16px; 
    position: relative;
    background-color: #3d3c5f;
    // div {
    //   padding:20px 0px 20px 0px;
    // }
    margin-top:10px;
    transition:0.2s;
}
.paymentMethod:hover{
    background-color: #3f4461;
}
.popup {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 15em;
  
    background-color: black;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
  }
  .popupContent {
    font-size: 0.9rem;
    padding: 1em 2em 0.5em 2em;
    color: white;
    background-color: #302f4e;
    margin: auto;
    border-radius: 10px;
    max-width: 550px;
    height: auto;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  